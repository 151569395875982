import { CardSummary, CardsService } from 'api';
import { CardsListView } from 'components/cardsList/CardsListView';
import { useParams } from 'react-router-dom';
import { useCardsList } from 'services/cards-provider';

export const CardsByUsername = () => {
  const { username } = useParams();
  const name = username?.replace(/([A-Z])/g, ' $1').trim() || '';
  const fetchCardsByUsername = (
    page: number,
    size: number,
    sortBy: string,
    filterTags: string[]
  ): Promise<{ content?: CardSummary[]; totalPages?: number }> => {
    return CardsService.getAllUserCards(
      name,
      page,
      size,
      sortBy,
      filterTags
    ).then((data) => ({
      content: data.content,
      totalPages: data.totalPages
    }));
  };
  const {
    cards,
    isLoading,
    pageable,
    filterTags,
    getCardsOnPage,
    handleEditFilterTags
  } = useCardsList(fetchCardsByUsername);
  return (
    <CardsListView
      pageTitle={name}
      cards={cards}
      isLoading={isLoading}
      pageable={pageable}
      filterTags={filterTags}
      getCardsOnPage={getCardsOnPage}
      setFilterTags={handleEditFilterTags}
      noCardsMessage="No cards found for this user."
    />
  );
};
