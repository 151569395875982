/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Card } from '../models/Card';
import type { CardHistory } from '../models/CardHistory';
import type { CardInput } from '../models/CardInput';
import type { Page } from '../models/Page';
import type { Tags } from '../models/Tags';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CardsService {

    /**
     * Retrieves all cards
     * @param page
     * @param size
     * @param sort
     * @param tags
     * @returns Page OK
     * @throws ApiError
     */
    public static getAllCards(
        page?: number,
        size?: number,
        sort?: string,
        tags?: Tags,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'tags': tags,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Create a card
     * @param requestBody
     * @returns Card OK
     * @throws ApiError
     */
    public static createCard(
        requestBody?: CardInput,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/cards',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves all 'verified' cards
     * @param page
     * @param size
     * @param sort
     * @param tags
     * @returns Page OK
     * @throws ApiError
     */
    public static getAllVerifiedCards(
        page?: number,
        size?: number,
        sort?: string,
        tags?: Tags,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/verified',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'tags': tags,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves all 'verified' cards from the current week
     * @param page
     * @param size
     * @param sort
     * @param tags
     * @returns Page OK
     * @throws ApiError
     */
    public static getAllVerifiedCardsThisWeek(
        page?: number,
        size?: number,
        sort?: string,
        tags?: Tags,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/verified/thisWeek',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'tags': tags,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Searches for cards
     * @param searchString
     * @param page
     * @param size
     * @param sort
     * @param tags
     * @returns Page OK
     * @throws ApiError
     */
    public static searchAllCards(
        searchString: string,
        page?: number,
        size?: number,
        sort?: string,
        tags?: Tags,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/search',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'tags': tags,
                'searchString': searchString,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves a single card
     * @param id ID of the desired card
     * @returns Card OK
     * @throws ApiError
     */
    public static getCardById(
        id: number,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update a card
     * @param id ID of the desired nomenclature
     * @param requestBody
     * @returns Card OK
     * @throws ApiError
     */
    public static updateCardById(
        id: number,
        requestBody?: CardInput,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/cards/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete a card with the specified id
     * @param id ID of the desired card
     * @returns void
     * @throws ApiError
     */
    public static deleteCardById(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/cards/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves the history of a card
     * @param id ID of the desired card
     * @returns CardHistory OK
     * @throws ApiError
     */
    public static getCardHistoryById(
        id: number,
    ): CancelablePromise<Array<CardHistory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/{id}/history',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves all cards by status
     * @param page
     * @param size
     * @param sort
     * @param tags
     * @param status
     * @returns Page OK
     * @throws ApiError
     */
    public static getAllCardsByStatus(
        page?: number,
        size?: number,
        sort?: string,
        tags?: Tags,
        status?: 'verified' | 'archived' | 'pending',
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/status',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'tags': tags,
                'status': status,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update a card status
     * @param id ID of the desired nomenclature
     * @param status Status of the card
     * @returns Card OK
     * @throws ApiError
     */
    public static updateCardStatusById(
        id: number,
        status: 'VERIFIED' | 'ARCHIVED',
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/cards/{id}/status',
            path: {
                'id': id,
            },
            query: {
                'status': status,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update a card status to verified
     * @param id ID of the desired nomenclature
     * @returns Card OK
     * @throws ApiError
     */
    public static updateCardStatusToVerifiedById(
        id: number,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/cards/{id}/verify',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves all user cards
     * @param username Username of the desired user
     * @param page
     * @param size
     * @param sort
     * @param tags
     * @returns Page OK
     * @throws ApiError
     */
    public static getAllUserCards(
        username: string,
        page?: number,
        size?: number,
        sort?: string,
        tags?: Tags,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/user/{username}',
            path: {
                'username': username,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'tags': tags,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Updates card categories
     * @param id Card id
     * @param requestBody
     * @returns Card OK
     * @throws ApiError
     */
    public static updateCardCategories(
        id: number,
        requestBody?: {
            categories: Array<number>;
        },
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/cards/{id}/categories',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get cards by category id
     * @param id Category id
     * @param page
     * @param size
     * @param sort
     * @param tags
     * @returns Page OK
     * @throws ApiError
     */
    public static getCardsByCategoryId(
        id: number,
        page?: number,
        size?: number,
        sort?: string,
        tags?: Tags,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/categories/{id}',
            path: {
                'id': id,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'tags': tags,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get new cards by category id
     * @param id Category id
     * @param page
     * @param size
     * @param sort
     * @param tags
     * @returns Page OK
     * @throws ApiError
     */
    public static getNewCardsByCategoryId(
        id: number,
        page?: number,
        size?: number,
        sort?: string,
        tags?: Tags,
    ): CancelablePromise<Page> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/cards/categories/{id}/new',
            path: {
                'id': id,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'tags': tags,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
