import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Container, Drawer, IconButton, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import './Header.scss';
import { viravaService } from 'config/virava';
import {
  ROUTE_CARDS,
  ROUTE_CATEGORIES,
  ROUTE_CREATE_CARD,
  ROUTE_THIS_WEEK_VERIFIED_CARDS,
  ROUTE_VERIFIED
} from 'utils/constants/routes';
import { useUser } from 'utils/context/userContext';
import CleverPineLogo from 'assets/images/cleverpine-logo.svg';
import { navLink, navWrapper } from 'styles/components/NavLink';

export const Header: React.FC = (): JSX.Element => {
  const { user } = useUser();

  const handleLogout = () => {
    viravaService.logout();
  };

  const [isMenuOen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOen);
  };

  const navTemplate = (
    <Box sx={navWrapper}>
      <ListItem component={NavLink} to={ROUTE_CATEGORIES} sx={navLink}>
        Home
      </ListItem>
      <ListItem component={NavLink} to={ROUTE_CARDS} sx={navLink}>
        {user.isAdmin ? 'Admin cards' : 'My cards'}
      </ListItem>
      <ListItem component={NavLink} to={ROUTE_VERIFIED} sx={navLink}>
        Verified
      </ListItem>
      <ListItem
        component={NavLink}
        to={ROUTE_THIS_WEEK_VERIFIED_CARDS}
        sx={navLink}
      >
        Hot
      </ListItem>
      <ListItem component={NavLink} to={ROUTE_CREATE_CARD} sx={navLink}>
        Create new
      </ListItem>
    </Box>
  );

  const drawerTemplate = (
    <Drawer
      ModalProps={{
        keepMounted: true
      }}
      anchor="top"
      open={isMenuOen}
      variant="temporary"
      onClose={toggleMenu}
      onClick={toggleMenu}
      disableScrollLock
      sx={{
        display: { xs: 'block', md: 'none' }
      }}
    >
      {navTemplate}
    </Drawer>
  );

  return (
    <header className="header-wrapper">
      <div className="header-container">
        <Container className="container-wrapper">
          <div className="header-left-side">
            <ListItem
              component={NavLink}
              to={ROUTE_CATEGORIES}
              sx={{ padding: 0 }}
            >
              <Box
                component="img"
                src={`${CleverPineLogo}`}
                alt="Cleverpine logo"
                className="cleverpine-logo"
              />
            </ListItem>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {navTemplate}
            </Box>
            {drawerTemplate}
          </div>
          <Box className="header-right-side">
            <IconButton
              onClick={toggleMenu}
              color="inherit"
              sx={{
                display: { xs: 'flex', md: 'none' }
              }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton color="inherit" onClick={handleLogout}>
              <PowerSettingsNewIcon />
            </IconButton>
            {/*
                TODO make profile button
              <button className="profile"></button> */}
          </Box>
        </Container>
      </div>
    </header>
  );
};
