import { Status } from 'api';

export const getStatusColor = (status: Status) => {
  switch (status) {
    case Status.PENDING:
      return 'warning';
    case Status.VERIFIED:
      return 'primary';
    case Status.DRAFT:
      return 'info';
    case Status.ARCHIVED:
      return 'error';
    default:
      return 'warning';
  }
};
