import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  FormControl,
  MenuItem,
  Typography
} from '@mui/material';
import * as Yup from 'yup';
import { CardsService, CategoriesService, Category, Status } from 'api';
import {
  showErrorToastMessage,
  showSuccessToastMessage
} from 'shared/Toast/Toast';
import { SUCCESSFULLY_VERIFIED_MESSAGE } from 'utils/constants/constants';
import { ROUTE_CARDS } from 'utils/constants/routes';

export const VerifyForm = ({
  setCard,
  card,
  isOpen,
  setIsOpen,
  state
}: any) => {
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formRef = useRef<any>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const validationSchema = Yup.object({
    checkbox: Yup.array()
      .min(1, 'Check at least one category')
      .of(Yup.string().required('Required'))
  });

  const formValues = {
    checkbox: selectedCategories
  };

  const getAllCategories = async () => {
    const categories = await CategoriesService.getAllCategories();
    setAllCategories(categories);
  };

  const getSuggestedCategories = async () => {
    setIsLoading(true);

    const data = await CategoriesService.getSuggestedCategories({
      title: card.title,
      description: card.description
    });
    setSelectedCategories(data.map((category) => category.id));

    setIsLoading(false);
    formRef.current.setFieldValue(
      'checkbox',
      data.map((category) => category.id)
    );
  };

  useEffect(() => {
    if (card.categories.length > 0) {
      setSelectedCategories(
        card.categories.map((category: Category) => category.id)
      );
      formRef.current.setFieldValue(
        'checkbox',
        card.categories.map((category: Category) => category.id)
      );
    } else {
      getSuggestedCategories();
    }

    getAllCategories();
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (event: any, category: Category, setFieldValue: any) => {
    if (event.target.checked) {
      setSelectedCategories((prev: any) => {
        const newSelected = [...prev, category.id];
        setFieldValue('checkbox', newSelected);
        return newSelected;
      });
    } else {
      setSelectedCategories((prev: any) => {
        const newSelected = prev.filter((idx: string) => idx !== category.id);
        setFieldValue('checkbox', newSelected);
        return newSelected;
      });
    }
  };

  const updateCard = async () => {
    if (card.status === Status.PENDING) {
      await CardsService.updateCardStatusToVerifiedById(Number(card?.id));
    }
    try {
      const updatedCard = await CardsService.updateCardCategories(
        Number(card?.id),
        { categories: selectedCategories }
      );
      setCard(updatedCard);
      setIsOpen(false);
      setSelectedCategories([]);
      showSuccessToastMessage(SUCCESSFULLY_VERIFIED_MESSAGE);
      searchParams.set('page', state);

      const params =
        searchParams.toString() === 'page=null'
          ? 'page=1'
          : searchParams.toString();

      navigate(`${ROUTE_CARDS}?${params}`);
    } catch (error: any) {
      showErrorToastMessage(error);
    }
  };

  return isOpen ? (
    <Box>
      <Typography sx={{ mb: 2 }}>Choose from this categories:</Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={updateCard}
          validateOnMoount={false}
          innerRef={formRef}
        >
          {({ errors, setFieldValue }) => {
            return (
              <Form name="checkbox">
                <FormControl>
                  {allCategories.map((category) => {
                    return (
                      <MenuItem
                        key={category.id}
                        value={category.id}
                        disableRipple
                        sx={{
                          px: 0,
                          ':hover': { backgroundColor: 'transparent' },
                          cursor: 'unset'
                        }}
                      >
                        <Checkbox
                          checked={selectedCategories.indexOf(category.id) > -1}
                          onChange={(event) =>
                            handleChange(event, category, setFieldValue)
                          }
                          name="checkbox"
                          sx={{ paddingLeft: 0 }}
                        />
                        <Typography>{category.name}</Typography>
                      </MenuItem>
                    );
                  })}

                  <Typography sx={{ color: 'error.main', height: '30px' }}>
                    {errors?.checkbox as string}
                  </Typography>
                </FormControl>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit" autoFocus>
                    {card.status === Status.VERIFIED ? 'Save' : 'Verify'}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  ) : null;
};
