/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Status {
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED',
    ARCHIVED = 'ARCHIVED',
    DRAFT = 'DRAFT',
}
