export const SLIDER_CONFIG = {
  defaultValue: 2,
  min: 2,
  max: 50,
  step: 1,
  marks: [
    {
      value: 2,
      label: '2x'
    },
    {
      value: 25,
      label: '25x'
    },
    {
      value: 50,
      label: '50x'
    }
  ]
};

export const getLabel = (value: number) => {
  return `${value}x`;
};
