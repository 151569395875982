import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import theme from '../../../styles/theme/white-theme';

type TextFieldTypes = {
  name: string;
  label: string;
  placeholder?: string;
  showInfoIcon: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

export const TextFieldComponent = ({
  name,
  label,
  placeholder,
  showInfoIcon,
  setFieldValue
}: TextFieldTypes) => (
  <>
    <Field
      as={TextField}
      name={name}
      label={label}
      variant="outlined"
      fullWidth
      margin="normal"
      placeholder={placeholder}
      onChange={(e: any) => {
        setFieldValue(name, e.target.value);
      }}
      InputProps={{
        endAdornment: showInfoIcon && (
          <InputAdornment position="end" style={{ outline: 'none' }}>
            <Tooltip title="Get you conversation link from chatGPT. For more information how it works see https://help.openai.com/en/articles/7925741-chatgpt-shared-links-faq">
              <InfoOutlinedIcon color="primary" sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </InputAdornment>
        )
      }}
    />
    <ErrorMessage name={name}>
      {(msg) => (
        <div
          style={{
            color: theme.palette.error.main,
            fontSize: '14px',
            fontStyle: 'italic'
          }}
        >
          {msg}
        </div>
      )}
    </ErrorMessage>
  </>
);
