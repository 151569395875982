export const ribbon = {
  width: '100px',
  position: 'absolute',
  top: -10,
  right: -52,
  transform: 'rotate(45deg)',
  backgroundColor: 'primary.main',
  color: 'white',
  padding: '8px 16px',
  textTransform: 'uppercase',
  textAlign: 'center',
  lineHeight: '1',
  letterSpacing: '.1em',
  zIndex: 1,
  overflow: 'hidden',
  '&:before, &:after': {
    position: 'absolute',
    zIndex: -1,
    content: "' '",
    bottom: '100%',
    width: '100%',
    top: '100%'
  },
  '&:before': {
    right: '52%',
    borderTop: '3px solid green',
    borderLeft: '3px solid transparent'
  },
  '&:after': {
    left: '52%',
    borderBottom: '3px solid green',
    borderRight: '3px solid transparent'
  }
};
