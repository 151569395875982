// import React, { useContext, ReactNode, useMemo, useCallback } from 'react';

import { ReactNode } from 'react';

// import { authConfig, virava } from 'config/authConfig';

// import { Auth } from 'types/Auth';

// import { ACCESS_TOKEN, TOAST_ERROR_MESSAGE } from 'utils/constants/constants';
// import { USER_DATA_CONFIG } from 'utils/constants/context/userContext';

// import { showErrorToastMessage } from 'components/shared/Toast/Toast';

// import { useUser } from './UserContext';

// const AuthContext = React.createContext<Auth>({
//   isAuthenticated: () => false,
//   login: async () => {},
//   register: async () => {},
//   resetPassword: async () => {},
//   logout: async () => {}
// });

// virava.init(authConfig);
// export const AuthProvider = ({ children }: { children: ReactNode }) => {
//   const { setData } = useUser();

//   const getToken = useCallback(() => {
//     try {
//       return localStorage.getItem(ACCESS_TOKEN);
//     } catch (error: any) {
//       if (error.detail) {
//         showErrorToastMessage(error.detail);
//       } else {
//         showErrorToastMessage(TOAST_ERROR_MESSAGE);
//       }
//       return null;
//     }
//   }, []);

//   const isAuthenticated = useCallback(() => {
//     const token = getToken();
//     if (token && token !== 'undefined' && token !== 'null') {
//       return true;
//     }
//     return false;
//   }, []);

//   const login = useCallback(
//     async (username: string, password: string): Promise<void> => {
//       try {
//         await virava.login(username, password);
//       } catch (error: any) {
//         if (error.detail) {
//           showErrorToastMessage(error.detail);
//         } else {
//           showErrorToastMessage(TOAST_ERROR_MESSAGE);
//         }
//       }
//     },
//     []
//   );

//   const register = useCallback(
//     async (
//       username: string,
//       password: string,
//       confirmPassword: string
//     ): Promise<void> => {
//       try {
//         await virava.register(username, password, confirmPassword);
//       } catch (error: any) {
//         if (error.detail) {
//           showErrorToastMessage(error.detail);
//         } else {
//           showErrorToastMessage(TOAST_ERROR_MESSAGE);
//         }
//       }
//     },
//     []
//   );

//   const resetPassword = useCallback(async (username: string): Promise<void> => {
//     try {
//       await virava.resetPassword(username);
//     } catch (error: any) {
//       if (error.detail) {
//         showErrorToastMessage(error.detail);
//       } else {
//         showErrorToastMessage(TOAST_ERROR_MESSAGE);
//       }
//     }
//   }, []);

//   const logout = useCallback(async (): Promise<void> => {
//     try {
//       await virava.logout();
//       setData(USER_DATA_CONFIG);
//     } catch (error: any) {
//       if (error.detail) {
//         showErrorToastMessage(error.detail);
//       } else {
//         showErrorToastMessage(TOAST_ERROR_MESSAGE);
//       }
//     }
//   }, []);

//   const context = useMemo(
//     () => ({
//       isAuthenticated,
//       login,
//       register,
//       resetPassword,
//       logout
//     }),
//     [isAuthenticated, login, register, resetPassword, logout]
//   );

//   return (
//     <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   return useContext(AuthContext);
// };

export const useAuth: any = () => ({
  login: () => {},
  logout: () => {},
  isAuthenticated: () => true
});
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  return (
    <>
      Auth
      {children}
    </>
  );
};
