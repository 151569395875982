import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider, CssBaseline, Box } from '@mui/material';

import './App.scss';
import { UserProvider } from 'utils/context/userContext';
import { AppRoutes } from './AppRoutes';
import theme from './styles/theme/white-theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Suspense>
          <UserProvider>
            <ToastContainer />
            <AppRoutes />
            <Box id="modal" />
          </UserProvider>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
