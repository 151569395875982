import BoltIcon from '@mui/icons-material/Bolt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { CardActions, CardHeader, Chip, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { CardSummary, Status } from 'api';
import { useNavigate } from 'react-router-dom';
import { ID_PARAMETER, USERNAME_PARAMETER } from 'utils/constants/constants';
import {
  ROUTE_SINGLE_CARD_EDIT,
  ROUTE_SINGLE_USER_CARDS
} from 'utils/constants/routes';
import { getStatusColor } from 'utils/getStatusColor';
import { useUser } from 'utils/context/userContext';
import { ribbon } from './OutlinedCardStyles';

type CardMethods = {
  actions?: { [key: string]: Function };
  isNew?: boolean;
};

type CardProps = Partial<CardSummary> & CardMethods;

export const OutlinedCard: React.FC<CardProps> = ({
  id,
  title,
  summary,
  tags,
  status,
  actions,
  productivityMultiplier,
  author,
  isNew
}: Partial<CardSummary> & CardMethods) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    actions!.delete(e, id);
  };

  const handleAddTag = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tag: string
  ) => {
    e.stopPropagation();
    actions!.addTagToFilter(e, tag);
  };

  const titleAndAuthor = (
    <Box sx={{ position: 'relative' }}>
      {author && (
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              ROUTE_SINGLE_USER_CARDS.replace(
                USERNAME_PARAMETER,
                author.replace(/\s/g, '')
              ),
              '_blank'
            );
          }}
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            width: 'fit-content',
            ':hover': { color: 'primary.main' }
          }}
          color="text.secondary"
          gutterBottom
        >
          {author}
        </Typography>
      )}
      {isNew ? <Typography sx={ribbon}>New</Typography> : null}
      <Typography variant="h5" sx={{ pb: 1, maxWidth: '256px' }}>
        <Typography
          sx={{
            mr: 1,
            fontSize: '1.5rem',
            wordBreak: 'break-word'
          }}
          component="span"
        >
          {title}
        </Typography>
        {productivityMultiplier && (
          <Chip
            icon={<BoltIcon color="warning" />}
            label={`x${productivityMultiplier}`}
            size="small"
            variant="outlined"
          />
        )}
      </Typography>
    </Box>
  );

  if (!id) {
    return null;
  }

  return (
    <Box
      sx={{ height: '100%', cursor: 'pointer' }}
      onClick={actions?.open && actions.open(id)}
    >
      <MuiCard variant="outlined" sx={{ height: '100%' }}>
        <CardHeader
          action={
            <CardActions disableSpacing>
              {actions?.edit && (
                <IconButton
                  aria-label="pen"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      ROUTE_SINGLE_CARD_EDIT.replace(ID_PARAMETER, String(id))
                    );
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {actions?.delete &&
                (user.isAdmin ||
                  (user.userName === author && status === Status.DRAFT)) &&
                status !== Status.VERIFIED && (
                  <IconButton aria-label="bin" onClick={handleDelete}>
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
            </CardActions>
          }
          title={titleAndAuthor}
          subheader={
            <Chip label={status} color={getStatusColor(status!)} size="small" />
          }
        />
        <CardContent>
          <Typography>{summary}</Typography>
          <Box mt={1}>
            {tags?.map((tag, idx) => {
              return tag.length ? (
                <Chip
                  clickable
                  key={`${tag}-${idx}}`}
                  label={tag}
                  variant="outlined"
                  onClick={(e) => handleAddTag(e, tag)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ) : null;
            })}
          </Box>
        </CardContent>
      </MuiCard>
    </Box>
  );
};
