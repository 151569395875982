/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutoSaveConfig } from '../models/AutoSaveConfig';
import type { Config } from '../models/Config';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigService {

    /**
     * Update the date of the config
     * @param requestBody
     * @returns Config OK
     * @throws ApiError
     */
    public static updateConfigDate(
        requestBody?: {
            date?: string;
        },
    ): CancelablePromise<Config> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/config/date',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete the date of the config
     * @returns void
     * @throws ApiError
     */
    public static deleteConfigDate(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/config/date',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves the autoSave config
     * @returns AutoSaveConfig OK
     * @throws ApiError
     */
    public static getAutoSaveConfig(): CancelablePromise<AutoSaveConfig> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/autosave',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update the autoSave config
     * @param requestBody
     * @returns AutoSaveConfig OK
     * @throws ApiError
     */
    public static updateAutoSaveConfig(
        requestBody?: AutoSaveConfig,
    ): CancelablePromise<AutoSaveConfig> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/config/autosave',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves admin draft visibility config
     * @returns Config OK
     * @throws ApiError
     */
    public static getAdminDraftVisibilityConfig(): CancelablePromise<Config> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/adminDraftVisibility',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update admin draft visibility config
     * @param requestBody
     * @returns Config OK
     * @throws ApiError
     */
    public static updateAdminDraftVisibilityConfig(
        requestBody?: {
            toggle?: boolean;
        },
    ): CancelablePromise<Config> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/config/adminDraftVisibility',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves draft notification config
     * @returns Config OK
     * @throws ApiError
     */
    public static getDraftNotificationConfig(): CancelablePromise<Config> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/draftNotification',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update draft notification config
     * @param requestBody
     * @returns Config OK
     * @throws ApiError
     */
    public static updateDraftNotificationConfig(
        requestBody?: {
            days?: number;
        },
    ): CancelablePromise<Config> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/config/draftNotification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves pending notification config
     * @returns Config OK
     * @throws ApiError
     */
    public static getPendingNotificationConfig(): CancelablePromise<Config> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/pendingNotification',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update pending notification config
     * @param requestBody
     * @returns Config OK
     * @throws ApiError
     */
    public static updatePendingNotificationConfig(
        requestBody?: {
            days?: number;
        },
    ): CancelablePromise<Config> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/config/pendingNotification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
