import { ROUTE_CARDS } from './constants/routes';

export const handleEdit = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  id: number
) => {
  e.stopPropagation();
  const url = `${window.location.origin}/${ROUTE_CARDS}/${id}/edit`;
  window.open(url, '_blank');
};
