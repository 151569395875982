import { ApiError } from 'api';
import { toast } from 'react-toastify';

import { TOAST_ERROR_MESSAGE } from 'utils/constants/constants';

export const showErrorToastMessage = (errorMessage?: ApiError | string) => {
  let error;

  if (errorMessage instanceof ApiError) {
    error = errorMessage.body.error.detail as string;
  } else if (errorMessage?.length) {
    error = errorMessage;
  } else {
    error = TOAST_ERROR_MESSAGE;
  }

  toast.error(`${error}`, {
    position: toast.POSITION.TOP_RIGHT
  });
};

export const showSuccessToastMessage = (successMessage?: string) => {
  toast.success(`${successMessage}`, {
    position: toast.POSITION.TOP_RIGHT
  });
};
