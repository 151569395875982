import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, TextField, Typography } from '@mui/material';

import { ROUTE_HOME } from 'utils/constants/routes';
import { useAuth } from '../../../services/auth-provider';
import './Login.scss';

export const Login: React.FC = (): JSX.Element => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = (e: any) => {
    e.preventDefault();
    login('Admin', 'Cleverpine21').then(() => {
      navigate(ROUTE_HOME, { replace: true });
    });
  };

  return (
    <div className="sign-in-wrapper">
      <Box className="sign-in">
        <Typography component="h1" variant="h3" sx={{ fontWeight: 600 }}>
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleLogin}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Typography
            component="p"
            className="text"
            sx={{ fontSize: 14, mt: 1, mb: 1 }}
          >
            Fields that are marked with * sign are required.
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="sign-in-btn"
            sx={{ mt: 1, mb: 2, color: '#FFFFFF' }}
            color="primary"
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </div>
  );
};
