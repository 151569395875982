import { CardSummary, CardsService, CategoriesService, Category } from 'api';
import { CardsListView } from 'components/cardsList/CardsListView';
import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useCardsList } from 'services/cards-provider';
import { VERIFIED_AT } from 'utils/constants/constants';

export const CardsByCategoryList = () => {
  const { id } = useParams();

  const [categories, setCategories] = useState<Category[]>([]);

  const getAllCategories = async () => {
    const data = await CategoriesService.getAllCategories();
    setCategories(data);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const categoryName = categories.find((category) => category.id === id)?.name;

  const fetchCardsByCategoryId = (
    page: number,
    size: number,
    sortBy: string,
    filterTags: string[]
  ): Promise<{ content?: CardSummary[]; totalPages?: number }> => {
    return CardsService.getCardsByCategoryId(
      Number(id),
      page,
      size,
      sortBy,
      filterTags
    ).then((data) => ({
      content: data.content,
      totalPages: data.totalPages
    }));
  };

  const fetchNewCardsByCategoryId = (
    page: number,
    size: number,
    sortBy: string,
    filterTags: string[]
  ): Promise<{ content?: CardSummary[]; totalPages?: number }> => {
    return CardsService.getNewCardsByCategoryId(
      Number(id),
      page,
      size,
      sortBy,
      filterTags
    ).then((data) => ({
      content: data.content,
      totalPages: data.totalPages
    }));
  };

  const {
    cards,
    isLoading,
    pageable,
    filterTags,
    getCardsOnPage,
    handleEditFilterTags
  } = useCardsList(fetchCardsByCategoryId, VERIFIED_AT);

  const { cards: newCards } = useCardsList(fetchNewCardsByCategoryId);

  return (
    <CardsListView
      pageTitle={categoryName || ''}
      cards={cards}
      isLoading={isLoading}
      pageable={pageable}
      filterTags={filterTags}
      getCardsOnPage={getCardsOnPage}
      setFilterTags={handleEditFilterTags}
      noCardsMessage="No cards found in this category."
      newCardsIds={newCards.map((card) => card.id) as number[]}
    />
  );
};
