export const ID_PARAMETER = ':id';
export const USERNAME_PARAMETER = ':username';
export const ID = 'id';
export const VERIFIED_AT = 'verifiedAt';
export const TOAST_ERROR_MESSAGE = 'Unexpected error occurred.';
export const SUCCESSFULLY_CREATED_MESSAGE = 'Card is successfully created.';
export const SUCCESSFULLY_PUBLISHED_MESSAGE = 'Card is successfully published.';
export const SUCCESSFULLY_UPDATED_MESSAGE = 'Card is successfully updated.';
export const SUCCESSFULLY_VERIFIED_MESSAGE = 'Card is successfully verified.';
export const SUCCESSFULLY_DELETED_MESSAGE = 'Card deleted successfully.';
