import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useCallback, useEffect, useState } from 'react';

export const useCKEditor = (initialData: string) => {
  const [data, setData] = useState(initialData);
  const [ckSource, setCKSource] = useState();

  const onContentChange = useCallback(
    (_: unknown, currEditor: ClassicEditor) => {
      setData(currEditor.getData());
    },
    []
  );

  useEffect(() => {
    if ((window as any).CKSource) {
      setCKSource((window as any).CKSource);
    }
  }, []);

  return {
    data,
    ckSource,
    onContentChange,
    setData
  };
};
