import React, { useContext, ReactNode, useState, useMemo } from 'react';
import { UserInfo } from 'api/models/UserInfo';

export const USER_DATA_CONFIG = {
  userName: undefined,
  isAdmin: undefined
};

type UserData = {
  user: UserInfo;
  setUser: (userData: UserInfo) => void;
};

const UserContext = React.createContext<UserData>({
  user: {},
  setUser: () => ''
});

interface UserProviderProps {
  readonly children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUserData] = useState<UserInfo>(USER_DATA_CONFIG);

  const setUser = (newData: UserInfo) => {
    setUserData(newData);
  };

  const context = useMemo(
    () => ({
      user,
      setUser
    }),
    [user]
  );
  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export const useUser = (): UserData => {
  return useContext(UserContext);
};
