import { Box, Slider, InputLabel } from '@mui/material';
import { getLabel, SLIDER_CONFIG } from 'utils/slider';

interface SliderWrapperProps {
  readonly value: number;
  readonly name: string;
  readonly label: string;
  readonly setValue: (name: string, value: number) => void;
}

export const SliderWrapper = ({
  value,
  name,
  label,
  setValue
}: SliderWrapperProps) => {
  return (
    <Box sx={{ maxWidth: { xs: '100%', md: '50%' } }}>
      <InputLabel sx={{ my: 1 }}>
        {label}: {value}x
      </InputLabel>
      <Slider
        name={name}
        aria-label={label}
        getAriaValueText={getLabel}
        valueLabelDisplay="auto"
        step={SLIDER_CONFIG.step}
        min={SLIDER_CONFIG.min}
        max={SLIDER_CONFIG.max}
        marks={SLIDER_CONFIG.marks}
        value={value}
        onChange={(_, newValue) => {
          setValue(name, newValue as number);
        }}
        sx={{ marginLeft: '10px' }}
      />
    </Box>
  );
};
