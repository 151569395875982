import { useEffect, useState } from 'react';
import { Badge, CardContent, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MuiCard from '@mui/material/Card';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { ROUTE_SINGLE_CATEGORY } from 'utils/constants/routes';
import { CategoriesService, Category } from 'api';
import { ID_PARAMETER } from 'utils/constants/constants';

export const CategoriesList = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Category[]>([]);

  const getAllCategories = async () => {
    const data = await CategoriesService.getAllCategories();
    setCategories(data);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const openSingleCategory = (idx: string) => () => {
    navigate(ROUTE_SINGLE_CATEGORY.replace(ID_PARAMETER, String(idx)));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ my: 2 }} component="div">
        Categories
      </Typography>
      <Grid container spacing={3}>
        {categories.map((category: Category) => (
          <Grid
            key={category.id}
            item
            xs={12}
            onClick={openSingleCategory(category.id!)}
          >
            <MuiCard
              variant="outlined"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                maxWidth: {
                  xs: '100%'
                },
                textAlign: 'center',
                p: 2,
                minHeight: '80px',

                cursor: 'pointer',
                '&:hover': { backgroundColor: '#f5f5f5' }
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 !important'
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {category.name}
                </Typography>
              </CardContent>
              {category.newCardsCount ? (
                <Badge
                  color="primary"
                  badgeContent={category.newCardsCount}
                  sx={{ alignSelf: 'flex-start' }}
                >
                  <NotificationsActiveIcon />
                </Badge>
              ) : null}
            </MuiCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
