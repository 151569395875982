import { CardsService } from 'api';
import { CardsListView } from 'components/cardsList/CardsListView';
import { useCardsList } from 'services/cards-provider';

export const VerifiedCardsThisWeekList: React.FC = (): JSX.Element => {
  const {
    cards,
    isLoading,
    pageable,
    filterTags,
    getCardsOnPage,
    handleEditFilterTags
  } = useCardsList(CardsService.getAllVerifiedCardsThisWeek);

  return (
    <CardsListView
      pageTitle="Verified cards this week"
      cards={cards}
      isLoading={isLoading}
      pageable={pageable}
      filterTags={filterTags}
      getCardsOnPage={getCardsOnPage}
      setFilterTags={handleEditFilterTags}
      noCardsMessage="No verified cards found this week."
    />
  );
};
