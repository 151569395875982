import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth-provider';

interface Props {
  component: React.ComponentType;
  path?: string;
}

export const ProtectedRoute: React.FC<Props> = ({
  component: RouteComponent
}): JSX.Element => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated() ? <RouteComponent /> : <Navigate to="/login" />;
};
