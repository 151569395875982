/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Category } from '../models/Category';
import type { CategorySuggestionInput } from '../models/CategorySuggestionInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoriesService {

    /**
     * Retrieves all categories
     * @returns Category OK
     * @throws ApiError
     */
    public static getAllCategories(): CancelablePromise<Array<Category>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/categories',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Retrieves suggested categories
     * @param requestBody
     * @returns Category OK
     * @throws ApiError
     */
    public static getSuggestedCategories(
        requestBody?: CategorySuggestionInput,
    ): CancelablePromise<Array<Category>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/categories/suggest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
