import { ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Pagination,
  Typography,
  Link
} from '@mui/material';
import { CardSummary, CardsService, Page, Status } from 'api';
import { OutlinedCard } from 'components/card/OutlinedCard';
import {
  ID_PARAMETER,
  SUCCESSFULLY_DELETED_MESSAGE
} from 'utils/constants/constants';
import { ROUTE_SINGLE_CARD } from 'utils/constants/routes';
import { handleEdit } from 'utils/editHelper';
import { navLink } from 'styles/components/NavLink';
import {
  showErrorToastMessage,
  showSuccessToastMessage
} from 'shared/Toast/Toast';

interface CardsListViewProps {
  pageTitle: string;
  cards: CardSummary[];
  isLoading: boolean;
  pageable: Page;
  filterTags: string[];
  getCardsOnPage: (
    event: any,
    page: number,
    filter: Array<string>
  ) => Promise<void>;
  setFilterTags: (tags: string[]) => void;
  allowEdit?: boolean;
  noCardsMessage: string;
  linkToCreate?: string;
  newCardsIds?: number[];
}

export const CardsListView: React.FC<CardsListViewProps> = ({
  pageTitle,
  cards,
  isLoading,
  pageable,
  filterTags,
  getCardsOnPage,
  setFilterTags,
  allowEdit = false,
  noCardsMessage,
  linkToCreate,
  newCardsIds = []
}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const openSingleCard = (idx: number) => () => {
    navigate(ROUTE_SINGLE_CARD.replace(ID_PARAMETER, String(idx)), {
      state: location.search.replace(/\D/g, '')
    });
  };

  const removeTag = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tag: string
  ) => {
    const filteredTags = filterTags.filter((t) => t !== tag);
    setFilterTags(filteredTags);
    getCardsOnPage(e, pageable.page!, filteredTags);
  };

  const handleChange = (e: ChangeEvent<unknown>, page: number) => {
    getCardsOnPage(e, page, filterTags);
  };

  const handleDelete = async (e: any, idx: number) => {
    try {
      await CardsService.deleteCardById(idx);
      showSuccessToastMessage(SUCCESSFULLY_DELETED_MESSAGE);
    } catch (error: any) {
      showErrorToastMessage(error);
    }
    getCardsOnPage(e, Number(pageable.page), filterTags);
  };

  const addTagToFilter = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tag: string
  ) => {
    if (!filterTags.includes(tag.toLowerCase())) {
      setFilterTags([...filterTags, tag.toLowerCase()]);
      getCardsOnPage(e, 1, [...filterTags, tag.toLowerCase()]);
    }
  };

  const getActions = (status: Status) => {
    let actions: { [key: string]: Function } = {
      open: openSingleCard,
      addTagToFilter,
      delete: handleDelete
    };

    if (allowEdit && (status === Status.PENDING || status === Status.DRAFT)) {
      actions = { ...actions, edit: handleEdit };
    }

    return actions;
  };

  const showFilterTags = filterTags.map((tag, idx) => (
    <Chip
      key={`${tag}-${idx}`}
      label={tag}
      clickable
      sx={{ mr: 1 }}
      onDelete={(e) => removeTag(e, tag)}
      variant="outlined"
    />
  ));

  const cardHtml = cards.map(
    ({ id, title, summary, tags, status, productivityMultiplier, author }) => {
      if (!title && status !== Status.DRAFT) {
        return null;
      }

      return (
        <Grid key={id} item xs={12} md={4}>
          <OutlinedCard
            id={id}
            title={title || 'This is a draft title'}
            summary={summary}
            tags={tags}
            status={status || Status.PENDING}
            actions={getActions(status!)}
            productivityMultiplier={productivityMultiplier}
            author={author}
            isNew={newCardsIds.includes(Number(id))}
          />
        </Grid>
      );
    }
  );

  if (isLoading) {
    return (
      <Box className="spinner">
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (cards.length === 0) {
    return (
      <Container>
        <Typography variant="h4" gutterBottom sx={{ my: 2 }} component="div">
          {noCardsMessage}
          <div>{showFilterTags}</div>
        </Typography>
        {linkToCreate && (
          <Link
            href={linkToCreate}
            sx={{ ...navLink, m: 0, color: '#a6ba32' }}
            underline="always"
          >
            Start here
          </Link>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ my: 2 }} component="div">
        {pageTitle}
        <div>{showFilterTags}</div>
      </Typography>
      <Grid container spacing={2}>
        {cardHtml}
      </Grid>
      <Pagination
        onChange={handleChange}
        page={pageable.page}
        count={pageable.totalPages}
        sx={{ my: 2 }}
      />
    </Container>
  );
};
