import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { UsersService, SystemService } from 'api';
import { CircularProgress } from '@mui/material';
import PineconeBackground from 'assets/images/pinecone.svg';
import { useUser } from 'utils/context/userContext';
import { Header } from '../Header/Header';

import './MainLayout.scss';

import packageJson from '../../../../package.json';

export const MainLayout: React.FC = (): JSX.Element => {
  const { user, setUser } = useUser();
  const [beVersion, setBeVersion] = useState<string>('');

  const getUser = async () => {
    const userData = await UsersService.getUserInfo();
    setUser(userData);
  };

  const getBeVersion = async () => {
    try {
      const response = await SystemService.getVersion();
      setBeVersion(response.version as string);
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUser();
    getBeVersion();
  }, []);

  if (!user.userName) {
    return <CircularProgress />;
  }

  return (
    <>
      <Header />
      <Outlet />
      <img
        src={PineconeBackground}
        className="pinecone"
        alt="grey-pinecone-background"
      />
      <div className="app-version">
        FE v{packageJson.version} / BE v{beVersion}
      </div>
    </>
  );
};
