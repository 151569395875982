import BoltIcon from '@mui/icons-material/Bolt';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Link,
  Typography
} from '@mui/material';
import MuiCard from '@mui/material/Card';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Card, CardsService, Status } from 'api';
import { Loader } from 'components/loader/Loader';
import { getStatusColor } from 'utils/getStatusColor';

import './SingleCard.scss';
import { useModal } from 'hooks/useModal';
import { Modal } from 'components/shared/Modal/Modal';
import { VerifyForm } from 'components/forms/VerifyForm';
import {
  ROUTE_SINGLE_CARD_EDIT,
  ROUTE_SINGLE_CATEGORY
} from 'utils/constants/routes';
import { ID_PARAMETER } from 'utils/constants/constants';
import { useUser } from 'utils/context/userContext';

export const SingleCard: React.FC = (): JSX.Element => {
  const [card, setCard] = useState<Card>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useUser();

  const { setIsOpen, isOpen } = useModal();

  const getSingleCard = async (idx: string) => {
    setIsLoading(true);

    const cardDetails = await CardsService.getCardById(Number(idx));

    setCard(cardDetails);
    setIsLoading(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    getSingleCard(id!);
  }, []);

  if (isLoading || !card) {
    return <Loader />;
  }

  const titleAndAuthor = (
    <Box>
      {card.author && (
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {card.author}
        </Typography>
      )}
      <Typography variant="h5" component="div" sx={{ pb: 1 }}>
        <Typography component="span" sx={{ mr: 1 }}>
          {card.title}
        </Typography>
        {card.productivityMultiplier && (
          <Chip
            icon={<BoltIcon color="warning" />}
            label={`x${card.productivityMultiplier}`}
            size="small"
            variant="outlined"
          />
        )}
      </Typography>
    </Box>
  );

  return (
    <Container>
      <MuiCard variant="outlined" sx={{ mt: 2 }}>
        <CardHeader
          title={titleAndAuthor}
          subheader={
            <Chip
              label={card.status || Status.PENDING}
              color={getStatusColor(card.status!)}
            />
          }
        />
        <CardContent
          sx={{
            wordWrap: 'break-word'
          }}
        >
          <Typography variant="body1" gutterBottom>
            Card id: {id}
          </Typography>
          {card?.categories?.length ? (
            <Typography variant="body1" gutterBottom>
              Categories:
              {card.categories.map((category, index, array) => {
                const isLast = index === array.length - 1;
                return (
                  <Typography
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      ':hover': { color: 'primary.main' },
                      pl: 1
                    }}
                    onClick={() =>
                      navigate(
                        ROUTE_SINGLE_CATEGORY.replace(
                          ID_PARAMETER,
                          String(category.id)
                        )
                      )
                    }
                    key={category.id}
                  >
                    {category.name}
                    {!isLast && ', '}
                  </Typography>
                );
              })}
            </Typography>
          ) : (
            ''
          )}
          <Typography variant="h6" gutterBottom>
            <div dangerouslySetInnerHTML={{ __html: card.description || '' }} />
          </Typography>
          <Typography variant="h6" gutterBottom>
            {card.isWithLink ? (
              <Link target="blank" href={card.content!}>
                {card.content}
              </Link>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: card.content || '' }} />
            )}
          </Typography>
          <Box mt={1}>
            {card.tags?.map((tag, idx) => {
              return tag.length ? (
                <Chip
                  key={`${tag}-${idx}}`}
                  label={tag}
                  variant="outlined"
                  sx={{ mr: 1, mb: 1 }}
                />
              ) : null;
            })}
          </Box>
        </CardContent>
        {card.status !== Status.VERIFIED && (
          <CardActions>
            <>
              {card.status !== Status.ARCHIVED && (
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(
                      ROUTE_SINGLE_CARD_EDIT.replace(ID_PARAMETER, String(id))
                    )
                  }
                >
                  Edit
                </Button>
              )}
              {card.canVerify && card.status === Status.PENDING && (
                <Button variant="outlined" color="primary" onClick={handleOpen}>
                  Verify
                </Button>
              )}
            </>
          </CardActions>
        )}
        {card.status === Status.VERIFIED && user.isAdmin && (
          <CardActions>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
              Change categories
            </Button>
          </CardActions>
        )}
      </MuiCard>
      <Modal
        isOpen={isOpen}
        headerTitle="Verify card"
        hide={() => setIsOpen(false)}
        sx={{
          minHeight: '600px'
        }}
      >
        <VerifyForm
          setCard={setCard}
          card={card}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          state={location.state}
        />
      </Modal>
    </Container>
  );
};
