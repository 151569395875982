import { Box, CircularProgress } from '@mui/material';

import './Loader.scss';

export const Loader: React.FC = (): JSX.Element => {
  return (
    <Box className="loader-container">
      <CircularProgress size={60} />
    </Box>
  );
};
