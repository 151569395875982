import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Box, InputLabel, Typography } from '@mui/material';
import { IS_REQUIRED } from 'utils/constants/errorMessages';

export const CkEditorWrapper = ({
  label,
  errorMessage,
  setError,
  ckSource,
  data,
  onChange
}: {
  label?: string;
  errorMessage: string;
  setError: (error: string) => void;
  ckSource: any;
  data: string;
  onChange: (_: unknown, currEditor: ClassicEditor) => void;
}) => (
  <>
    {label && (
      <InputLabel htmlFor={label} sx={{ my: 1 }}>
        {label}
      </InputLabel>
    )}
    {ckSource && (
      <Box>
        <CKEditor
          editor={(ckSource as any).Editor}
          config={{ removePlugins: ['Title'] }}
          data={data}
          onBlur={(event, editor) => {
            if (editor.getData().length === 0) {
              setError(`${label} ${IS_REQUIRED}`);
            } else {
              setError('');
            }
          }}
          onChange={onChange}
        />
        {errorMessage.length ? (
          <Typography
            sx={{ fontSize: '14px', fontStyle: 'italic', color: 'error.main' }}
            color="error"
          >
            {errorMessage}
          </Typography>
        ) : null}
      </Box>
    )}
  </>
);
