import { Routes, Route } from 'react-router-dom';

import { CreateCard } from 'pages/CreateCard/CreateCard';
import { SingleCard } from 'pages/SingleCard/SingleCard';
import { VerifiedCardsList } from 'pages/VerifiedCardsList/VerifiedCardsList';
import { VerifiedCardsThisWeekList } from 'pages/VerifiedCardsThisWeekList/VerifiedCardsThisWeekList';
import { CategoriesList } from 'pages/CategoriesList/CategoriesList';
import { CardsByCategoryList } from 'pages/CardsByCategoryList/CardsByCategoryList';
import { CardsByUsername } from 'pages/CardsByUsername/CardsByUsername';
import { CardsList } from './pages/CardsList/CardsList';
import { Login } from './pages/Authentication/Login/Login';
import { MainLayout } from './components/layout/MainLayout/MainLayout';
import { ProtectedRoute } from './components/layout/ProtectedRoute/ProtectedRoute';
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_CREATE_CARD,
  ROUTE_SINGLE_CARD,
  ROUTE_SINGLE_CARD_EDIT,
  ROUTE_VERIFIED,
  ROUTE_THIS_WEEK_VERIFIED_CARDS,
  ROUTE_CATEGORIES,
  ROUTE_SINGLE_CATEGORY,
  ROUTE_CARDS,
  ROUTE_SINGLE_USER_CARDS
} from './utils/constants/routes';

export const AppRoutes: React.FC = (): JSX.Element => {
  return (
    <Routes>
      <Route path={ROUTE_HOME} element={<MainLayout />}>
        <Route index element={<ProtectedRoute component={CategoriesList} />} />
        <Route path={ROUTE_LOGIN} element={<Login />} />
        <Route
          path={ROUTE_CARDS}
          element={<ProtectedRoute component={CardsList} />}
        />
        <Route
          path={ROUTE_CATEGORIES}
          element={<ProtectedRoute component={CategoriesList} />}
        />
        <Route
          path={ROUTE_SINGLE_CATEGORY}
          element={<ProtectedRoute component={CardsByCategoryList} />}
        />
        <Route
          path={ROUTE_VERIFIED}
          element={<ProtectedRoute component={VerifiedCardsList} />}
        />
        <Route
          path={ROUTE_THIS_WEEK_VERIFIED_CARDS}
          element={<ProtectedRoute component={VerifiedCardsThisWeekList} />}
        />
        <Route
          path={ROUTE_SINGLE_CARD}
          element={<ProtectedRoute component={SingleCard} />}
        />
        <Route
          path={ROUTE_SINGLE_USER_CARDS}
          element={<ProtectedRoute component={CardsByUsername} />}
        />
        <Route
          path={ROUTE_SINGLE_CARD_EDIT}
          element={<ProtectedRoute component={CreateCard} />}
        />
        <Route
          path={ROUTE_CREATE_CARD}
          element={<ProtectedRoute component={CreateCard} />}
        />
      </Route>
    </Routes>
  );
};
