import { Box, Button, Typography } from '@mui/material';

interface ConfirmationFormProps {
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly confirmationContentText: string;
  readonly confirmationBtnTitle: string;
}

export const ConfirmationForm = ({
  onConfirm,
  onCancel,
  confirmationContentText,
  confirmationBtnTitle
}: ConfirmationFormProps) => {
  return (
    <Box>
      <Typography sx={{ marginBottom: '40px', color: '#00000099' }}>
        {confirmationContentText}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '20px',
          marginTop: '20px'
        }}
      >
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          sx={{ color: '#00000099' }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {confirmationBtnTitle}
        </Button>
      </Box>
    </Box>
  );
};
