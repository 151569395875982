export const ROUTE_HOME = '/';
export const ROUTE_CARDS = '/cards';
export const ROUTE_CATEGORIES = '/categories';
export const ROUTE_USER = '/user';
export const ROUTE_SINGLE_CATEGORY = `${ROUTE_CATEGORIES}/:id`;
export const ROUTE_SINGLE_CARD = `${ROUTE_CARDS}/:id`;
export const ROUTE_SINGLE_CARD_EDIT = `${ROUTE_CARDS}/:id/edit`;
export const ROUTE_SINGLE_USER_CARDS = `${ROUTE_CARDS}${ROUTE_USER}/:username`;
export const ROUTE_CREATE_CARD = '/create';
export const ROUTE_VERIFIED = '/verified';
export const ROUTE_VERIFIED_CARDS = '/verified-cards';
export const ROUTE_THIS_WEEK_VERIFIED_CARDS = `${ROUTE_VERIFIED_CARDS}/week`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_REGISTER = `/register`;
