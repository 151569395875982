import { KeycloakConfigDefault, KeycloakServiceDefault } from 'virava';

export const authConfig = (appConfig: any): KeycloakConfigDefault => {
  return {
    clientId: appConfig.REACT_APP_KEYCLOAK_CLIENT_ID,
    baseUrl: appConfig.REACT_APP_KEYCLOAK_BASE_URL,
    realm: appConfig.REACT_APP_KEYCLOAK_REALM
  };
};

export const viravaService = new KeycloakServiceDefault();
