import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#a6ba32'
    },
    secondary: {
      main: '#FFFFFF'
    },
    error: {
      main: '#ed4337'
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Regular',
    h1: {
      fontSize: 60,
      fontWeight: 600,
      lineHeight: '72px',
      marginBottom: 16
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '32px',
      marginBottom: 8
    },
    h3: {
      fontSize: 16,
      fontWeight: 200,
      lineHeight: '32px'
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Bold'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#ffffff'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          color: '#ffffff'
        }
      }
    }
  }
});

export default theme;
