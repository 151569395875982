import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Chip, Container, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  AutoSaveConfig,
  CardInput,
  CardsService,
  ConfigService,
  Status
} from 'api';
import { Loader } from 'components/loader/Loader';
import {
  ROUTE_SINGLE_CARD,
  ROUTE_SINGLE_CARD_EDIT
} from 'utils/constants/routes';
import { formatDate } from 'utils/dateFormatHelper';
import { getStatusColor } from 'utils/getStatusColor';
import { SLIDER_CONFIG } from 'utils/slider';
import {
  ID_PARAMETER,
  SUCCESSFULLY_CREATED_MESSAGE,
  SUCCESSFULLY_PUBLISHED_MESSAGE,
  SUCCESSFULLY_UPDATED_MESSAGE
} from 'utils/constants/constants';
import {
  LINK_IS_REQUIRED,
  PROMPT_IS_REQUIRED,
  TAGS_ARE_REQUIRED,
  TITLE_IS_REQUIRED,
  USE_CASE_IS_REQUIRED
} from 'utils/constants/errorMessages';
import { useModal } from 'hooks/useModal';
import { Modal } from 'components/shared/Modal/Modal';
import { ConfirmationForm } from 'components/forms/ConfirmationForm';
import {
  showErrorToastMessage,
  showSuccessToastMessage
} from 'shared/Toast/Toast';
import { CkEditorWrapper } from './components/CkEditorWrapper';
import { SliderWrapper } from './components/SliderWrapper';
import { TextFieldComponent } from './components/TextField';
import { useCKEditor } from './hooks/useCKEditor';

import { editorTemplate } from './EditorTemplate';
import { promptTemplate } from './PromptTemplate';
import { useCaseTemplate } from './UseCaseTemplate';

export type FormValues = {
  title: string;
  description: string;
  prompt: string;
  tags: string;
  link: string;
  productivityMultiplier: number;
};

const initialValues: FormValues = {
  title: '',
  description: useCaseTemplate,
  prompt: promptTemplate,
  tags: '',
  link: '',
  productivityMultiplier: SLIDER_CONFIG.defaultValue
};

const initialConfigValues: AutoSaveConfig = {
  timeInterval: 120000,
  keyStrokes: 100
};

export const CreateCard = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef<any>(null);
  const { setIsOpen, isOpen } = useModal();

  // Old format of card is when description is null
  const [isOld, setIsOld] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [cardStatus, setCardStatus] = useState<Status | any>('DRAFT');
  const [isCardWithLink, setIsCardWithLink] = useState(false);
  const [keyStrokeCount, setKeyStrokeCount] = useState(0);
  const [cardLastUpdate, setCardLastUpdate] = useState('');
  const [promptErrorMessage, setPromptErrorMessage] = useState<string>('');
  const [useCaseErrorMessage, setUseCaseErrorMessage] = useState<string>('');
  const [autoSaveConfiguration, setAutoSaveConfiguration] =
    useState<AutoSaveConfig>({ ...initialConfigValues });

  const validationBaseSchema = Yup.object().shape({
    title: Yup.string().required(TITLE_IS_REQUIRED),
    tags: Yup.string().required(TAGS_ARE_REQUIRED)
  });

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const validationExtendedSchema = validationBaseSchema.concat(
    Yup.object().shape({
      link: Yup.string()
        .required(LINK_IS_REQUIRED)
        .matches(urlRegex, 'Please provide a valid link')
    })
  );
  const savingLockRef = useRef(false);

  const getAutoSaveConfiguration = async () => {
    const config = await ConfigService.getAutoSaveConfig();
    setAutoSaveConfiguration(config);
  };

  useEffect(() => {
    getAutoSaveConfiguration();
  }, []);

  const {
    data: prompt,
    ckSource,
    // onEditorReady: onPromptEditorReady,
    onContentChange: onPromptChange,
    setData: setPrompt
  } = useCKEditor(isOld ? editorTemplate : promptTemplate);
  const {
    data: useCase,
    // onEditorReady: onUseCaseEditorReady,
    onContentChange: onUseCaseChange,
    setData: setUseCase
  } = useCKEditor(useCaseTemplate);

  const saveInterval = useRef<NodeJS.Timeout | null>(null);

  const isEditMode = useMemo(
    () => location.pathname.includes('edit'),
    [location.pathname]
  );
  const isCreateMode = useMemo(
    () => location.pathname.includes('create'),
    [location.pathname]
  );

  useEffect(() => {
    if (isCreateMode && !isEditMode) {
      setCardLastUpdate('');
    }
  }, [isEditMode, isCreateMode]);

  const getSingleCard = useCallback(
    async (idx: string) => {
      const cardDetails = await CardsService.getCardById(Number(idx));

      setFormValues({
        ...formValues,
        title: cardDetails.title || '',
        tags: cardDetails.tags?.join(',') || '',
        link: cardDetails.isWithLink! ? (cardDetails.content as string) : '',
        productivityMultiplier:
          cardDetails.productivityMultiplier || SLIDER_CONFIG.defaultValue
      });
      setCardStatus(cardDetails.status as Status);
      setIsCardWithLink(cardDetails.isWithLink!);
      setCardLastUpdate(cardDetails.lastSavedAt!);

      let description = '';
      if (cardDetails.description === null) {
        setIsOld(true);
      } else {
        description = cardDetails.description as string;
      }

      setPrompt(cardDetails.content as string);
      setUseCase(description);
    },

    [setPrompt, setUseCase, setFormValues, formValues]
  );

  useEffect(() => {
    const fetchData = async () => {
      formRef.current?.resetForm();

      if (isCreateMode) {
        setUseCase(useCaseTemplate);
        setPrompt(promptTemplate);
        setFormValues({ ...initialValues });
      }

      if (isEditMode) {
        await getSingleCard(id!);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [location.pathname, isCreateMode, isEditMode]);

  const handleKeyStroke = () => {
    setKeyStrokeCount((prev) => prev + 1);
  };

  const handlePaste = useCallback(
    (e: any) => {
      const text = e.clipboardData.getData('text/plain');

      setKeyStrokeCount((prev) => prev + text.length);
    },
    [formRef]
  );

  const createAsDraft = useCallback(async () => {
    const values = formRef.current?.values;

    if (!values) {
      return;
    }

    const { link, tags, productivityMultiplier } = values;

    const cardData = {
      title:
        formRef.current.values.title.length > 0
          ? formRef.current.values.title
          : `Autosaved on ${new Date().toLocaleString('en-GB', {
              timeZone: 'Europe/Athens'
            })}`,
      tags: tags.toLowerCase().split(','),
      productivityMultiplier,
      content: link,
      description: useCase,
      status: CardInput.status.DRAFT,
      isWithLink: true
    };

    try {
      const card = await CardsService.createCard(cardData);
      setCardLastUpdate(card.lastSavedAt!);
      showSuccessToastMessage(SUCCESSFULLY_CREATED_MESSAGE);
      navigate(ROUTE_SINGLE_CARD_EDIT.replace(ID_PARAMETER, String(card.id)));
      setKeyStrokeCount(0);
    } catch (error: any) {
      showErrorToastMessage(error);
    }
  }, [formRef, useCase, prompt]);

  const autoSave = async (isButtonClicked?: boolean) => {
    const values = formRef.current?.values;

    if (!values) {
      return;
    }

    const { title, link, tags, productivityMultiplier } = values;
    try {
      const card = await CardsService.updateCardById(Number(id), {
        title:
          title.length > 0
            ? title
            : `Autosaved on ${new Date().toLocaleString('en-GB', {
                timeZone: 'Europe/Athens'
              })}`,
        tags: tags.toLowerCase().split(','),
        productivityMultiplier,
        content: isCardWithLink ? link : prompt,
        description: useCase,
        status: CardInput.status.DRAFT,
        isWithLink: !!(link.length || isCardWithLink)
      });

      formRef.current.setFieldValue(
        'title',
        formRef.current.values.title.length > 0
          ? formRef.current.values.title
          : `Autosaved on ${new Date().toLocaleString('en-GB', {
              timeZone: 'Europe/Athens'
            })}`
      );
      setCardLastUpdate(card.lastSavedAt!);
      setKeyStrokeCount(0);
      isButtonClicked && showSuccessToastMessage(SUCCESSFULLY_UPDATED_MESSAGE);
    } catch (error: any) {
      isButtonClicked && showErrorToastMessage(error);
    }
  };

  useEffect(() => {
    if (keyStrokeCount >= autoSaveConfiguration.keyStrokes!) {
      if (!savingLockRef.current) {
        savingLockRef.current = true;
        if (isEditMode) {
          autoSave().finally(() => {
            savingLockRef.current = false;
          });
        }

        if (isCreateMode) {
          createAsDraft().finally(() => {
            savingLockRef.current = false;
          });
        }
      }
    }
  }, [keyStrokeCount, isEditMode, isCreateMode]);

  useEffect(() => {
    const saveIntervalId = setInterval(() => {
      if (!savingLockRef.current) {
        savingLockRef.current = true;
        if (isEditMode) {
          autoSave().finally(() => {
            savingLockRef.current = false;
          });
        }

        if (isCreateMode) {
          createAsDraft().finally(() => {
            savingLockRef.current = false;
          });
        }
      }
    }, autoSaveConfiguration.timeInterval);

    return () => {
      clearInterval(saveIntervalId);
    };
  }, [formRef?.current?.values, prompt, useCase]);

  const handleFormSubmit = useCallback(
    async ({ title, tags, link, productivityMultiplier }: FormValues) => {
      const tagsArray = tags.toLowerCase().split(',');

      const cardData = {
        title:
          title.length > 0
            ? title
            : `Autosaved on ${new Date().toLocaleString('en-GB', {
                timeZone: 'Europe/Athens'
              })}`,
        description: isOld ? undefined : useCase,
        tags: tagsArray,
        content: isCardWithLink ? link : prompt,
        productivityMultiplier,
        status: CardInput.status.PENDING,
        isWithLink: !!(link.length || isCardWithLink)
      };

      if (isEditMode) {
        try {
          const card = await CardsService.updateCardById(Number(id), cardData);
          formRef.current.setFieldValue(
            'title',
            title.length > 0
              ? title
              : `Autosaved on ${new Date().toLocaleString('en-GB', {
                  timeZone: 'Europe/Athens'
                })}`
          );
          setCardLastUpdate(card.lastSavedAt!);
          navigate(ROUTE_SINGLE_CARD.replace(ID_PARAMETER, String(id)));
          showSuccessToastMessage(SUCCESSFULLY_PUBLISHED_MESSAGE);
        } catch (error: any) {
          showErrorToastMessage(error);
        }
      } else {
        try {
          const card = await CardsService.createCard({
            ...cardData,
            isWithLink: true,
            content: link
          });
          setCardLastUpdate(card.lastSavedAt!);
          showSuccessToastMessage(SUCCESSFULLY_PUBLISHED_MESSAGE);
          navigate(ROUTE_SINGLE_CARD.replace(ID_PARAMETER, String(card.id)));
        } catch (error: any) {
          showErrorToastMessage(error);
        }
      }
      setIsOpen(false);
    },
    [isEditMode, id, prompt, useCase, navigate]
  );

  if (isLoading) {
    return <Loader />;
  }

  document.addEventListener('DOMContentLoaded', () => {
    const editor = document.getElementById('editor');
    let pasted = false;

    editor!.addEventListener('input', () => {
      if (!pasted) {
        pasted = true;
        return;
      }

      if (editor!.innerHTML === '' || editor!.innerHTML === '<br>') {
        editor!.innerHTML = 'Default content';
      }
    });

    editor!.addEventListener('paste', () => {
      pasted = true;
    });

    editor!.innerHTML = 'Default content';
  });

  const areFieldsFilled = (values: any) => {
    return !Object.values(values).some((value) => value === '');
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ my: 2 }} component="div">
        {isEditMode ? 'Edit card' : 'Create card'}
        <Chip
          label={isCreateMode ? 'DRAFT' : cardStatus}
          color={getStatusColor(isCreateMode ? Status.DRAFT : cardStatus!)}
          size="small"
          sx={{ ml: 2 }}
        />
      </Typography>
      <Formik
        initialValues={formValues}
        validationSchema={
          isCreateMode || isCardWithLink
            ? validationExtendedSchema
            : validationBaseSchema
        }
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        enableReinitialize
        validateOnMount
      >
        {({ isSubmitting, values, setFieldValue, isValid, dirty }) => {
          return (
            <Form onKeyDown={handleKeyStroke} onPasteCapture={handlePaste}>
              <Modal
                isOpen={isOpen}
                headerTitle="Publish card"
                hide={() => setIsOpen(false)}
                sx={{
                  minHeight: '600px'
                }}
              >
                <ConfirmationForm
                  onCancel={() => setIsOpen(false)}
                  onConfirm={() => {
                    if (isValid) {
                      handleFormSubmit(values);
                    }
                  }}
                  confirmationContentText="Are you sure you want to publish this card?"
                  confirmationBtnTitle="Publish"
                />
              </Modal>
              <TextFieldComponent
                name="title"
                label="Title"
                placeholder="[Enter a concise title for your card"
                showInfoIcon={false}
                setFieldValue={setFieldValue}
              />
              <SliderWrapper
                value={values.productivityMultiplier}
                name="productivityMultiplier"
                label="Productivity multiplier"
                setValue={setFieldValue}
              />
              {!isOld && (
                <CkEditorWrapper
                  label="Use case"
                  errorMessage={useCaseErrorMessage}
                  setError={setUseCaseErrorMessage}
                  ckSource={ckSource}
                  onChange={(event: any, editor) => {
                    const data = editor.getData();
                    setFieldValue('useCase', data);
                    onUseCaseChange(event, editor);
                  }}
                  data={useCase}
                />
              )}
              {isCardWithLink || isCreateMode ? (
                <TextFieldComponent
                  name="link"
                  label="Conversation link"
                  showInfoIcon
                  setFieldValue={setFieldValue}
                />
              ) : (
                <CkEditorWrapper
                  label={isOld ? undefined : 'Prompt'}
                  errorMessage={promptErrorMessage}
                  setError={setPromptErrorMessage}
                  ckSource={ckSource}
                  data={prompt}
                  onChange={(event: any, editor) => {
                    const data = editor.getData();
                    setFieldValue('prompt', data);
                    onPromptChange(event, editor);
                  }}
                />
              )}
              <TextFieldComponent
                name="tags"
                label="Tags (comma-separated)"
                showInfoIcon={false}
                setFieldValue={setFieldValue}
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 1,
                  marginBottom: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  {(cardStatus !== 'PENDING' || isCreateMode) && (
                    <Button
                      id="publish-button"
                      // type="submit"
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        (isCreateMode && !dirty) ||
                        (isEditMode &&
                          isCardWithLink &&
                          !areFieldsFilled(values)) ||
                        (isEditMode && !isCardWithLink && prompt === '') ||
                        (!isCardWithLink && prompt === '') ||
                        useCase === ''
                      }
                      variant="contained"
                      onClick={() => {
                        if ((prompt === '' || useCase === '') && !isValid) {
                          if (useCase === '') {
                            setUseCaseErrorMessage(USE_CASE_IS_REQUIRED);
                          }

                          if (!isCardWithLink && prompt === '') {
                            setPromptErrorMessage(PROMPT_IS_REQUIRED);
                          }
                        } else {
                          setIsOpen(true);
                        }
                      }}
                    >
                      Publish
                    </Button>
                  )}
                  {isCreateMode && (
                    <Button
                      sx={{ mx: 1 }}
                      onClick={createAsDraft}
                      variant="outlined"
                    >
                      Save as draft
                    </Button>
                  )}
                  {isEditMode && (
                    <Button
                      sx={{ mx: 1 }}
                      onClick={() => autoSave(true)}
                      variant="outlined"
                      disabled={
                        cardStatus === Status.PENDING
                          ? !isValid ||
                            !dirty ||
                            (!isCardWithLink && prompt === '') ||
                            useCase === ''
                          : !dirty
                      }
                    >
                      Save
                    </Button>
                  )}
                  <Button sx={{ mx: 1 }} onClick={() => navigate(-1)}>
                    Back
                  </Button>
                </Box>
                {cardLastUpdate.length > 0 && (
                  <Typography
                    variant="body1"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                  >
                    Your card was last saved at: {formatDate(cardLastUpdate)}
                  </Typography>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
