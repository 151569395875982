/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CardInput = {
    title: string;
    content: string;
    description?: string;
    isWithLink?: boolean;
    tags?: Array<string>;
    productivityMultiplier?: number;
    status?: CardInput.status;
};

export namespace CardInput {

    export enum status {
        PENDING = 'PENDING',
        DRAFT = 'DRAFT',
    }


}

