import ReactDOM from 'react-dom/client';

import { OpenAPI } from 'api';
import { authConfig, viravaService } from 'config/virava';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

async function getConfig(): Promise<any> {
  const response = await fetch(
    `${process.env.PUBLIC_URL}/config/env.config.json`
  );
  const data = await response.json();
  return data;
}

getConfig()
  .then(async (config) => {
    OpenAPI.BASE = config.REACT_APP_OPENAPI_BASE_URL as string;

    OpenAPI.TOKEN = async () => {
      const token = localStorage.getItem('access_token');
      return token ?? '';
    };

    const appConfig = authConfig(config);
    await viravaService.init(appConfig);

    viravaService
      .login()
      .then(() => {
        const root = ReactDOM.createRoot(
          document.getElementById('root') as HTMLElement
        );
        root.render(<App />);
      })
      .catch((error) => {
        console.error('Failed to login', error);
      });
  })
  .catch((error) => {
    console.error('Failed to get config', error);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
