import { CardSummary, Page } from 'api';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { INITIAL_PAGEABLE } from 'utils/constants/common';
import { ID } from 'utils/constants/constants';

interface CardsListHook {
  cards: CardSummary[];
  isLoading: boolean;
  pageable: Page;
  filterTags: string[];
  getCardsOnPage: (
    event: any,
    page: number,
    filter: Array<string>
  ) => Promise<void>;
  handleEditFilterTags: (tags: string[]) => void;
}

export const useCardsList = (
  fetchCardsFunction: (
    page: number,
    size: number,
    sortBy: string,
    filterTags: string[]
  ) => Promise<{ content?: CardSummary[]; totalPages?: number }>,
  sort?: string
): CardsListHook => {
  const [cards, setCards] = useState<CardSummary[]>([]);
  const [currentParams, setCurrentParams] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageable, setPageable] = useState<Page>({ ...INITIAL_PAGEABLE });
  const [filterTags, setFilterTags] = useState<string[]>([]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getCardsOnPage = async (
    event: any,
    page: number,
    filters: string[]
  ) => {
    setIsLoading(true);

    if (filters?.length! > 0) {
      searchParams.set('tags', filters!.join(','));
    }

    searchParams.set('page', page.toString());

    // page number has changed or a filter was applied, add a new entry in the history stack
    if (page !== pageable.page || filters!.join(',') !== filterTags.join(',')) {
      navigate({ search: `${searchParams}` });
    } else {
      // replace the current history entry
      navigate({ search: `${searchParams}` }, { replace: true });
    }

    const pageData = await fetchCardsFunction(
      page - 1,
      pageable.size || INITIAL_PAGEABLE.size,
      sort || ID,
      filters || []
    );

    setIsLoading(false);
    setPageable({
      ...pageable,
      totalPages: pageData.totalPages,
      page
    });

    if (pageData.content) {
      setCards(pageData.content);
    }
  };

  const handleEditFilterTags = (tags: string[]) => {
    // update URL with the filter tags
    if (tags.length > 0) {
      searchParams.set('tags', tags.join(','));
      searchParams.set('page', '1');
    } else {
      searchParams.delete('tags');
    }

    navigate({ search: `${searchParams}` });
    setFilterTags([...tags]);
  };

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || '1', 10);

    const filters = searchParams.get('tags')?.split(',') || [];

    setPageable({ ...pageable, page });

    setFilterTags(filters);

    if (currentParams === 0 || [...searchParams].length !== currentParams) {
      getCardsOnPage(null, page, filters);
    }

    setCurrentParams([...searchParams].length);
  }, [searchParams]);

  return {
    cards,
    isLoading,
    pageable,
    filterTags,
    getCardsOnPage,
    handleEditFilterTags
  };
};
