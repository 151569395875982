export const navWrapper = {
  display: 'flex',
  width: '100%',
  maxWidth: '1724px'
};

export const rightNavSide = {
  display: 'flex',
  alignItems: 'center'
};

export const navLink = {
  display: 'inline',
  fontSize: '20px',
  fontWeight: 500,
  margin: 'auto 12px',
  color: '#000000',
  whiteSpace: 'nowrap',
  paddingBottom: '0',

  '&:hover': {
    textDecoration: 'underline',
    color: 'primary.main'
  },

  '&.active': {
    color: 'primary.main'
  }
};

export const navIconStyle = {
  verticalAlign: 'middle',
  cursor: 'pointer'
};
